<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Infos Fournisseur</span>
      </template>
    <!-- FormAddFourni -->
     <b-card>

    <!-- media -->
    <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-img
        
          v-if="imagepreview" 
          :src="imagepreview" 
          height="80"
        />
      </b-link>
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
       <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
          <!-- <b-button class="mr-4 sm:mb-0 mb-2" @click="$refs.updateImgInput.click()">Importer votre Logo</b-button> -->
          <!-- <b-button type="border" color="danger" @click="imagepreview = null;is_logo=true">Supprimer l'image</b-button> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.updateImgInput.click()"
      >
        Importer Logo
      </b-button>
  

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        size="sm"
        class="mb-75 mr-75"
        @click="imagepreview = null"
      >
        Annuler
      </b-button> 
      <b-card-text>Type JPG, GIF or PNG. Max size of 800kB</b-card-text>
    </b-media-body>
  </b-media> 
    <!--/ media -->
 <!-- <div class="flex flex-wrap items-center mb-base">

      <vs-avatar v-if="imagepreview" :src="imagepreview" size="70px" class="mr-4 mb-4" />
      <div>
          <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
            <b-button class="mr-4 sm:mb-0 mb-2" @click="$refs.updateImgInput.click()">Importer votre Logo</b-button>
            <b-button type="border" color="danger" @click="imagepreview = null;is_logo=true">Supprimer l'image</b-button>
        <p class="text-sm mt-2">Type images autorisés JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div> -->
    <!-- form -->
    <validation-observer ref="formdata" #default="{invalid}">
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="ICE"
            label-for="account-ice"
          >
          <validation-provider
            #default="{ errors }"
            name="ICE"
            rules="required"
          >
            <b-form-input
              v-model="ice"
              placeholder="ICE"
              name="ice"
              :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nom Société"
            label-for="account-societe"
          >
          <validation-provider
            #default="{ errors }"
            name="Nom Société"
            rules="required"
          >
            <b-form-input
              v-model="nameSociete"
              name="nameSociete"
              placeholder="Nom Société"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Téléphone"
            label-for="account-phone"
          >
          <validation-provider
            #default="{ errors }"
            name="Phone"
            rules="required"
          >
            <b-form-input
              v-model="phone"
              name="phone"
              placeholder="0754236598"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Adresse"
            label-for="account-adresse"
          >
            <validation-provider
            #default="{ errors }"
            name="Adresse"
            rules="required"
          >
            <b-form-input
              v-model="adresse"
              name="adresse"
              placeholder="Adresse"
              :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

       

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="invalid"
            @click="saveFourni()"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
     </validation-observer>
  </b-card>
    
    </b-tab>
      <!-- <b-tab :disabled="isTrue">
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Produits Fournisseur</span>
      </template>

    <b-card>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          v-bind:key="index"
          ref="row"
        >

          <b-col md="4">
            <b-form-group
              label="Nom Produit"
              label-for="nameProd"
            >
            <validation-provider
            #default="{ errors }"
            name="Nom Produit"
            rules="required"
          >
              <b-form-input
                id="nameProd"
                type="text"
                placeholder="Nom Produit"
                v-model="item.nameProduct"
                :state="errors.length > 0 ? false:null" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Quantité"
              label-for="quantity"
            >
            <validation-provider
            #default="{ errors }"
            name="Quantité"
            rules="required"
          >
              <b-form-input
                id="quantity"
                type="number"
                placeholder="1"
                v-model="item.qnt"
                 :state="errors.length > 0 ? false:null"/>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Prix Achat"
              label-for="prixAchat"
            >
            <validation-provider
            #default="{ errors }"
            name="Prix Achat"
            rules="required"
          >
              <b-form-input
                id="prixAchat"
                type="number"
                placeholder="320"
                v-model="item.prixAchat" 
                 :state="errors.length > 0 ? false:null"/>
              </validation-provider>
            </b-form-group>
          </b-col>
          
           <b-col md="2">
            <b-form-group
              label="Prix Vente"
              label-for="prixvente"
            >
            <validation-provider
            #default="{ errors }"
            name="Prix De Vente"
            rules="required"
          >
              <b-form-input
                id="prixvente"
                type="number"
                placeholder="350"
                v-model="item.prixVente"
                 :state="errors.length > 0 ? false:null" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-row>
             <b-col md="4">
            <b-form-group
              label="Référence Produit"
              label-for="ref"
            >
            <validation-provider
            #default="{ errors }"
            name="Référence"
            rules="required"
          >
              <b-form-input
                id="ref"
                type="text"
                placeholder="#P25698"
                v-model="item.refProduit"/>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="TVA"
              label-for="tva"
            >
            <validation-provider
            #default="{ errors }"
            name="TVA"
            rules="required"
          >
              <b-form-input
                id="tva"
                type="text"
                placeholder="15%"
                v-model="item.tva"/>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Catégorie"
              label-for="catégorie"
            >
            <validation-provider
            #default="{ errors }"
            name="catégorie"
            rules="required"
          >
             <v-select
            v-model="item.category"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :clearable="false"
            input-id="category"
            label="name"
            class="mb-2 item-selector-title"
          />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Nouveau Produit</span>
    </b-button>
     <b-button style="margin-left: 12px;"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="saveProducts"
    >

      <span>Enregistrer</span>
    </b-button>
  </b-card>
    </b-tab>  -->

   
  </b-tabs>
</template>

<script>
import config from '@/config'
import {  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,BTabs, BTab } from 'bootstrap-vue'

import FormAddFourni from './FormAddFourni.vue'
import AddProductsFourni from './AddProductsFourni.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
export default {
  components: {
    BTabs,
    BTab, BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    FormAddFourni,
    AddProductsFourni,
    ValidationProvider, ValidationObserver,vSelect
  },
   mixins: [heightTransition],
  directives: {
    Ripple,
  },

  data() {
    return {
      required, alphaNum, email,
      options: {},
       image:null,
      imagepreview:null,
      ice:'',
      nameSociete:'',
      phone:'',
      adresse:'',
      items: [],
      categories:[],
      nextTodoId: 0,
      idFouni:null,
      isTrue:true,
      categoryID:{},
      UserID:{},

    
    }
  },
   methods: {
    imageSelected(e){
            this.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onload = e => {
                // this.is_logo=false
            this.imagepreview = e.target.result;
               };
         },
    resetForm() {
      this.ice=''
      this.nameSociete=''
      this.phone=''
      this.adresse=''
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
     repeateAgain() {
      this.items.push({
        id:this.idFouni,
         nameProduct:'',
         refProduit:'',
         prixAchat:'',
         prixVente:'',
         qnt:'',
         tva:'',
         category:this. categoryID.id
        // selected: "hello"+this.nextTodoId,
        // selected1: 'hi',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    saveProducts(){
      // console.log(this.items)
        let data = new FormData;
          data.append('products', JSON.stringify(this.items));
          console.log(Object.fromEntries(data))
          // data.append('NumFact', this.rows.NumFact);
        // this.$http.post(`${config.API_BASE_URL}/api/auth/createproductFour`,data)
        // .then(res => { 
        //   if(res.data=='Successfully add')      
        //    { this.$swal.fire({
        //           position: 'top-end',
        //           icon: 'success',
        //           title: 'Les produits sont  ajoutés',
        //           showConfirmButton: false,
        //           timer: 1000
        //         })}
       
        // }).catch(() => {

        //   this.$swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Something went wrong! Pleaz try again'
        //       })
        // })
          // console.log(this.idFouni)
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    saveFourni(){
       let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('image', this.image);
          data.append('nameSC', this.nameSociete);
          data.append('ice', this.ice);
          data.append('phone', this.phone);
          data.append('adresse', this.adresse);
          // console.log(Object.fromEntries(data))
          this.$http.post(`${config.API_BASE_URL}/api/auth/createFourni`,data)
        .then(res => {       
              if(res.data.message=='Successfully add'){ 
                this.idFouni=res.data.Fuid;
                this.isTrue=false;
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Votre Fournisseur bien ajouté',
                    showConfirmButton: false,
                    timer: 1000
                  })}
       
        })
        .catch(() => {

          this.$swal.fire({
                icon: 'errorrrr',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
        })
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
   mounted() {
    this.initTrHeight()
     this.$http.get(`${config.API_BASE_URL}/api/auth/getCategories`).then(res => { this.categories = res.data })
    this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>