<template>
  <b-card>
     <div>
       <!-- {{items}} -->
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- :style="{height: trHeight}" -->


        <!-- Row Loop -->
        <!-- :key="item.id" -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          v-bind:key="index"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="4">
            <b-form-group
              label="Nom Produit"
              label-for="nameProd"
            >
              <b-form-input
                id="nameProd"
                type="text"
                placeholder="Nom Produit"
                v-model="item.nameProduct"
              />
            </b-form-group>
          </b-col>

         

          <!-- Quantity -->
          <b-col md="2">
            <b-form-group
              label="Quantité"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                type="number"
                placeholder="1"
                v-model="item.qnt"
              />
            </b-form-group>
          </b-col>
           <!-- Cost -->
          <b-col md="2">
            <b-form-group
              label="Prix Achat"
              label-for="prixAchat"
            >
              <b-form-input
                id="prixAchat"
                type="number"
                placeholder="320"
                v-model="item.prixAchat"
              />
            </b-form-group>
          </b-col>
          <!-- Profession -->
          
           <b-col md="2">
            <b-form-group
              label="Prix Vente"
              label-for="prixvente"
            >
              <b-form-input
                id="prixvente"
                type="number"
                placeholder="350"
                v-model="item.prixVente"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-row>
             <b-col md="4">
            <b-form-group
              label="Référence Produit"
              label-for="ref"
            >
              <b-form-input
                id="ref"
                type="text"
                placeholder="#P25698"
                v-model="item.refProduit"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="TVA"
              label-for="tva"
            >
              <b-form-input
                id="tva"
                type="text"
                placeholder="15%"
                v-model="item.tva"
              />
            </b-form-group>
          </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Nouveau Produit</span>
    </b-button>
     <b-button style="margin-left: 12px;"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="saveProducts"
    >
      <!-- <feather-icon
        icon="PlusIcon"
        class="mr-25"
      /> -->
      <span>Enregistrer</span>
    </b-button>
  </b-card>
</template>

<script>
import config from '@/config'
// import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,BCard
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import { codeBasic } from './code'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        // {
        // id: 1,
        // nameProduct:'',
        // refProduit:'',
        // prixAchat:'',
        // prixVente:'',
        // qnt:'',
        // tva:'',
        // selected: 'male',
        // selected1: 'designer',
        // prevHeight: 0,
      // }
      ],
      // nameProduct:'',
      // refProduit:'',
      // prixAchat:'',
      // prixVente:'',
      // qnt:'',
      // tva:'',
      nextTodoId: 0,
    //   codeBasic,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
         nameProduct:'',
         refProduit:'',
         prixAchat:'',
         prixVente:'',
         qnt:'',
         tva:'',
        // selected: "hello"+this.nextTodoId,
        // selected1: 'hi',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    saveProducts(){

    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

