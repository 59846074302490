<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-img
        
          v-if="imagepreview" 
          :src="imagepreview" 
          height="80"
        />
      </b-link>
    </b-media-aside>

    <b-media-body class="mt-75 ml-75">
       <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
          <!-- <b-button class="mr-4 sm:mb-0 mb-2" @click="$refs.updateImgInput.click()">Importer votre Logo</b-button> -->
          <!-- <b-button type="border" color="danger" @click="imagepreview = null;is_logo=true">Supprimer l'image</b-button> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="mb-75 mr-75"
        @click="$refs.updateImgInput.click()"
      >
      <!-- $refs.refInputEl.$el.click() -->
        Importer Logo
      </b-button>
    <!--   <b-form-file
        ref="refInputEl"
        v-model="profileFile"
        accept=".jpg, .png, .gif"
        :hidden="true"
        plain
        @input="inputImageRenderer"
      />-->

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        size="sm"
        class="mb-75 mr-75"
        @click="imagepreview = null"
      >
        Annuler
      </b-button> 
      <b-card-text>Type JPG, GIF or PNG. Max size of 800kB</b-card-text>
    </b-media-body>
  </b-media> 
    <!--/ media -->
 <!-- <div class="flex flex-wrap items-center mb-base">

      <vs-avatar v-if="imagepreview" :src="imagepreview" size="70px" class="mr-4 mb-4" />
      <div>
          <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
            <b-button class="mr-4 sm:mb-0 mb-2" @click="$refs.updateImgInput.click()">Importer votre Logo</b-button>
            <b-button type="border" color="danger" @click="imagepreview = null;is_logo=true">Supprimer l'image</b-button>
        <p class="text-sm mt-2">Type images autorisés JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div> -->
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="ICE"
            label-for="account-ice"
          >
          <!-- v-model="optionsLocal.username" -->
            <b-form-input
              v-model="ice"
              placeholder="ICE"
              name="ice"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nom Société"
            label-for="account-societe"
          >
            <b-form-input
              v-model="nameSociete"
              name="nameSociete"
              placeholder="Nom Société"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Téléphone"
            label-for="account-phone"
          >
            <b-form-input
              v-model="phone"
              name="phone"
              placeholder="0754236598"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Adresse"
            label-for="account-adresse"
          >
            <b-form-input
              v-model="adresse"
              name="adresse"
              placeholder="Adresse"
            />
          </b-form-group>
        </b-col>

       

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="saveFourni()"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import config from '@/config'
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, 
  BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image:null,
      imagepreview:null,
      ice:'',
      nameSociete:'',
      phone:'',
      adresse:'',
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    imageSelected(e){
            this.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onload = e => {
                this.is_logo=false
            this.imagepreview = e.target.result;
               };
         },
    resetForm() {
      this.ice=''
      this.nameSociete=''
      this.phone=''
      this.adresse=''
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    saveFourni(){
       let data = new FormData;
          data.append('image', this.image);
          data.append('nameSC', this.nameSociete);
          data.append('ice', this.ice);
          data.append('phone', this.phone);
          data.append('adresse', this.adresse);
       this.$http.post(`${config.API_BASE_URL}api/auth/createFourni`,data)
        .then(res => {       
              // if(res.data=='Successfully add')
              console.log(res.data.Fuid)
            {  this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre Fournisseur bien ajouté',
                  showConfirmButton: false,
                  timer: 1000
                })}
       
        })
        .catch(() => {

          this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
        })
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
